import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import Levis from "../../assets/Levis.png";
import Branding from "src/components/branding";

export const Layout = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "neutral.800",
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md" sx={{ textAlign: "center" }}>
          <Typography sx={{ mb: 1 }} variant="h4">
            Plan your Buying with AI
          </Typography>
          <Typography sx={{ mb: 1 }} variant="h5">
            Welcome to Levis Buy Plan Environment
          </Typography>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ alignSelf: "flex-start", paddingLeft: 64 }}
          src={Levis}
          height={50}
        />
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flex: {
              xs: "1 1 auto",
              md: "0 0 auto",
            },
            flexDirection: "column",
            justifyContent: {
              md: "center",
            },
            maxWidth: "100%",
            p: {
              xs: 4,
              md: 8,
            },
            width: {
              md: 600,
            },
            paddingTop: "30px !important",
          }}
        >
          <div>{children}</div>
        </Box>
        <Branding color="black" />
      </div>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

import { createContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const STORAGE_KEY = "app.store";

const restoreStore = () => {
  let value = null;

  try {
    const restored = window.localStorage.getItem(STORAGE_KEY);

    if (restored) {
      value = JSON.parse(restored);
    }
  } catch (err) {
    console.error(err);
  }

  return value;
};

const deleteStore = () => {
  try {
    window.localStorage.removeItem(STORAGE_KEY);
  } catch (err) {
    console.error(err);
  }
};

const storeStore = (value) => {
  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};

const initialState = {
  cityName: null,
  storeName: null,
  regionName: null,
  stateName: null,
  storeCode: null,
  retailerCluster: null,
  storeId: null,
};

export const StoreContext = createContext({
  ...initialState,
  handleReset: () => {},
  handleUpdate: () => {},
});

export const StoreProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const restored = restoreStore();

    if (restored) {
      setState((prevState) => ({
        ...prevState,
        ...restored,
      }));
    }
  }, []);

  const handleReset = useCallback(() => {
    deleteStore();
    setState((prevState) => ({
      ...prevState,
      ...initialState,
    }));
  }, []);

  const handleUpdate = useCallback((store) => {
    setState((prevState) => {
      storeStore({
        cityName: prevState.cityName,
        storeName: prevState.storeName,
        regionName: prevState.regionName,
        stateName: prevState.stateName,
        retailerCluster: prevState?.retailerCluster,
        storeCode: prevState?.storeCode,
        storeId: prevState?.storeId,
        ...store,
      });

      return {
        ...prevState,
        ...store,
      };
    });
  }, []);

  return (
    <StoreContext.Provider
      value={{
        ...state,
        handleReset,
        handleUpdate,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const StoreConsumer = StoreContext.Consumer;

import { BreadcrumbsSeparator } from "src/components/breadcrumbs-separator";
import { useLocation } from "react-router-dom";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { SvgIcon } from "@mui/material";
import { Typography } from "@mui/material";
export const Breadcrumbs = () => {
  const location = useLocation();
  const path = location.pathname.split("/").filter((part) => part !== "");
  return (
    <>
      <SvgIcon fontSize="small">
        <HomeSmileIcon />
      </SvgIcon>
      <Typography
        color="text.secondary"
        component="h2"
        variant="overline"
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 3,
          gap: "5px",
          paddingLeft: "5px",
        }}
      >
        {path.map((part, index) => (
          <div key={index}>/ {part}</div>
        ))}
      </Typography>
    </>
  );
};

import { lazy } from "react";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "src/guards/guest-guard";
import { Layout as AuthLayout } from "src/layouts/auth/modern-layout";
// JWT
const JwtLoginPage = lazy(() => import("src/pages/auth/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/register"));

export const authRoutes = [
  {
    path: "auth",
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          </GuestGuard>
        ),
        children: [
          {
            path: "login",
            element: <JwtLoginPage />,
          },
          {
            path: "register",
            element: <JwtRegisterPage />,
          },
        ],
      },
    ],
  },
];

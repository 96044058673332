import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import Upload04Icon from "src/icons/untitled-ui/duocolor/upload-04";
import File01Icon from "src/icons/untitled-ui/duocolor/file-01";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { useAuth } from "src/hooks/use-auth";
import { isAdminRolePresent } from "src/utils/auth";
export const useSections = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  return useMemo(() => {
    const items = [
      {
        title: t(tokens.nav.MsfSummary),
        path: paths.dashboard.index,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.GcView),
        path: paths.dashboard.gcView,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.BrickView),
        path: paths.dashboard.brickView,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.BrickFitView),
        path: paths.dashboard.brickFitView,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
      {
        new: true,
        title: "Size Recommendation",
        path: paths.dashboard.sizingView,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
      {
        title: "Download Excel",
        path: paths.dashboard.reportsView,
        icon: (
          <SvgIcon fontSize="small">
            <Upload04Icon />
          </SvgIcon>
        ),
      },
    ];
    if (isAdminRolePresent(auth.user.roles)) {
      items.push({
        title: "Admin Section",
        path: paths.dashboard.adminView,
        icon: (
          <SvgIcon fontSize="small">
            <File01Icon />
          </SvgIcon>
        ),
      });
    }
    return [{ items }];
  }, [t]);
};

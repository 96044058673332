export const paths = {
  index: "/",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
  },
  dashboard: {
    index: "/dashboards",
    gcView: "/dashboards/GCView",
    brickView: "/dashboards/Brick",
    brickFitView: "/dashboards/BrickFit",
    adminView: "/dashboards/Admin",
    sizingView: "/dashboards/SizingRecommendation",
    reportsView: "/dashboards/Reports",
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  401: "/401",
  404: "/dashboards",
  500: "/500",
};

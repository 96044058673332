import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const MSFSummary = lazy(() => import("src/pages/dashboard/index"));
const GCView = lazy(() => import("src/pages/dashboard/gcView"));
const Brick = lazy(() => import("src/pages/dashboard/brick"));
const BrickFit = lazy(() => import("src/pages/dashboard/brickFit"));
const AdminView = lazy(() => import("src/pages/dashboard/Admin"));
const SizingView = lazy(() =>
  import("src/pages/dashboard/SizingRecommendation")
);
const ReportsView = lazy(() => import("src/pages/dashboard/Reports"));

export const dashboardRoutes = [
  {
    path: "",
    index: true,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
  },
  {
    path: "dashboards",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <MSFSummary />,
      },
      {
        path: "GCView",
        children: [
          {
            index: true,
            element: <GCView />,
          },
        ],
      },
      {
        path: "Brick",
        element: <Brick />,
      },
      {
        path: "BrickFit",
        element: <BrickFit />,
      },
      {
        path: "Admin",
        element: <AdminView />,
      },
      {
        path: "SizingRecommendation",
        element: <SizingView />,
      },
      {
        path: "Reports",
        element: <ReportsView />,
      },
    ],
  },
];

import { createContext, useCallback, useState } from "react";
import PropTypes from "prop-types";

const initialConfig = {
  activeEnv: null, // Initialize with null or your default value
};

export const ConfigContext = createContext({
  ...initialConfig,
  setActiveEnv: () => {},
  getActiveEnv: () => {},
});

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig);

  const setActiveEnv = useCallback((env) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      activeEnv: env,
    }));
  }, []);

  const getActiveEnv = useCallback(() => {
    return config.activeEnv;
  }, [config]);

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        setActiveEnv,
        getActiveEnv,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ConfigConsumer = ConfigContext.Consumer;

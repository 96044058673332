import { decode } from "src/utils/jwt";

import axios from "axios";
const STORAGE_KEY = "accessToken";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

export const getPersistedUser = () => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user) => {
  try {
    sessionStorage.setItem(STORAGE_KEY, user);
  } catch (err) {
    console.error(err);
  }
};

const apiUrl = process.env.REACT_APP_API_URL;

class AuthApi {
  async sendOtp(email) {
    // Call the send_otp API
    try {
      await axios.post(`${apiUrl}/api/v1/users/send_otp`, {
        email: email,
      });
      return { message: "OTP sent successfully." };
    } catch (error) {
      console.error("[Send OTP Api]: ", error);
      throw new Error("Failed to send OTP");
    }
  }

  async signIn(email, otp, password) {
    // Call the login API
    try {
      const response = await axios.post(`${apiUrl}/api/v1/users/login`, {
        email: email,
        otp: otp,
        password: password,
      });
      const token = response.data.data.token;
      persistUser(token);
      return { accessToken: token }; // Assuming the JWT is returned in this field
    } catch (error) {
      console.error("[Login Api]: ", error);
      throw new Error("Invalid OTP or email");
    }
  }

  async me() {
    const accessToken = getPersistedUser();
    // Decode the JWT and extract user information
    try {
      const decodedToken = decode(accessToken);

      // Ensure the token has the necessary user information
      if (!decodedToken || !decodedToken.user) {
        throw new Error("Invalid authorization token");
      }

      const { user } = decodedToken;

      return {
        id: user.id,
        email: user.email,
        roles: user.roles.map((role) => ({
          name: role.name,
          access_level: role.access_level,
        })),
      };
    } catch (error) {
      console.error("[Auth Api]: ", error);
      throw new Error("Internal server error");
    }
  }
}

export const authApi = new AuthApi();

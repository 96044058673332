import trLogo from "../assets/trlogo.png"; // Adjust the path according to your project structure

const Branding = ({ color = "white" }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        color: color,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <b>Made with ❤️ by</b>
      <img
        src={trLogo}
        alt="TrueReach AI Logo"
        style={{ marginLeft: "5px", height: 40 }}
      />
    </div>
  );
};

export default Branding;
